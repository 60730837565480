import React from "react";
import AppProvider from "./context/appContext";
import App from "./App";

export default () => {
  return (
    <AppProvider>
      <App />
    </AppProvider>
  );
};
